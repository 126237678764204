import React from "react"
import { Link } from "gatsby-plugin-react-i18next"

import GrandPrix from "../../../images/contents/story-contest-grand-prix.svg"
import Winning from "../../../images/contents/story-contest-winning.svg"
import classes from "./StoryContestWork.module.scss"

type Props = {
  data: {
    title: string
    author: string
    award?: "grandPrix" | "grandPrixCandidate" | "winning"
  }
  year: Year
}

type Year = "2021" | "2022" | "2023"

const StoryContestWork: React.FC<Props> = ({ data, year }) => {
  let URL
  switch (year) {
    case "2021":
      URL = "/result/2021/story_contest/"
      break
    case "2022":
      URL = "/result/2022/story_contest/"
      break
    case "2023":
      URL = "/event/story_contest/"
      break
  }

  return (
    <div className={classes.StoryContestWork}>
      {data.award === "grandPrix" ? (
        <GrandPrix className={classes.awardLogo} />
      ) : null}
      {data.award === "grandPrixCandidate" ? (
        <div className={classes.LogoContainer}>
          <Winning className={classes.awardLogo} />
          <p className={classes.GrandPrixCandidate}>大賞候補作品</p>
        </div>
      ) : null}
      {data.award === "winning" ? (
        <Winning className={classes.awardLogo} />
      ) : null}
      <p className={classes.Title}>{data.title}</p>
      <p className={classes.Author}>{data.author}</p>
      <Link to={URL + data.title}>作品を読む</Link>
    </div>
  )
}

export default StoryContestWork
