export const shortShortWorks2023 = [
  { title: "還りゆくケモノたち", author: "都築良継" },
  { title: "帰郷", author: "犬村ゼン" },
  { title: "俺の趣味", author: "Ring_chatot" },
  { title: "Back me to the ◯", author: "野良笛やおふう" },
  { title: "ぼくがオオカミの話をかく理由", author: "@Y" },
  { title: "やまびこ", author: "モチヅキ イチ" },
  { title: "海原の月", author: "ぺりかん" },
  { title: "きみがかえった日", author: "辰巳ルペ" },
]

export const shortWorks2023 = [
  { title: "背中合わせの恋人たちへ", author: "神木書房" },
  { title: "バックパック・トゥ・バック", author: "大竹　和竜" },
  { title: "Guardian of Materialism", author: "仁王立ちクララ" },
  { title: "嘘つき達の秘密基地", author: "野良笛やおふう" },
  { title: "今までありがとう", author: "Ring_chatot" },
  { title: "黒と白の境界線", author: "すあ" },
  { title: "肛門大探検", author: "だいご" },
  { title: "老狼（ロウロウ）と私", author: "あおなん" },
  { title: "背中合わせの約束", author: "ストロム" },
  { title: "リバース・エンジニアリング、アンド、リビング", author: "岸間夜行" },
  { title: "触れたい尻尾", author: "ラグス" },
]
