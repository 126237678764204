import React, { MouseEvent, ReactElement } from "react"

import classes from "./AnchorHead.module.scss"

type AnchorHeadProps = {
  title: string
  targetId: string
  tabId: string
}

const AnchorHead = (props: AnchorHeadProps): ReactElement => {
  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const tabElement = document.getElementById(props.tabId) as HTMLInputElement

    if (tabElement !== null) {
      tabElement.checked = true
    }
    const target = document.getElementById(props.targetId)
    let elementTop = 0
    if (target !== null) {
      elementTop = target.getBoundingClientRect().top
    }
    const targetTop = elementTop + window.scrollY
    document.documentElement.scrollTop = targetTop
  }

  return (
    <h3>
      <button className={classes.button} onClick={onClick}>
        {props.title}
      </button>
    </h3>
  )
}

export default AnchorHead
