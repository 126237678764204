import React from "react"

import StoryContestDecoration from "../../atoms/content/StoryContestDecoration"
import TwitterIcon from "../../../images/socialIcons/twitter.svg"
import LinkIcon from "../../../images/socialIcons/link.svg"
import classes from "./StoryContestWorkTitle.module.scss"

type Props = {
  title: string
  author: string
  twitter?: string
  web?: string
}

const StoryContestWorkTitle: React.FC<Props> = ({
  title,
  author,
  twitter,
  web,
}) => {
  return (
    <div className={classes.StoryContestWorkTitle}>
      <StoryContestDecoration />
      <div className={classes.TitleContainer}>
        <p className={classes.Title}>{title}</p>
        <p className={classes.Author}>{author}</p>
        {(twitter || web) && (
          <div className={classes.SocialIcons}>
            {twitter && (
              <a href={twitter} target="_blank" rel="noopener noreferrer">
                <TwitterIcon className={classes.SocialIcon} />
              </a>
            )}
            {web && (
              <a href={web} target="_blank" rel="noopener noreferrer">
                <LinkIcon className={classes.SocialIcon} />
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default StoryContestWorkTitle
