import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "gatsby-plugin-react-i18next"
import axios from "axios"
import { load } from "recaptcha-v3"

import Button from "../atoms/common/Button"
import classes from "./ContactForm.module.scss"

type FormData = {
  name: string
  email: string
  emailConfirm: string
  subject: string
  body: string
}

const baseURL = process.env.GATSBY_API_BASE_URL
const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY

const ContactForm: React.FC = () => {
  const [status, setStatus] = useState<
    "init" | "loading" | "success" | "failure"
  >("init")
  const { t } = useTranslation("contact")
  const { register, handleSubmit, errors, watch } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  })
  const onSubmit = async (data: FormData) => {
    setStatus("loading")

    const postData = {
      name: data.name,
      email: data.email,
      subject: data.subject,
      body: data.body,
      token: "",
    }

    if (recaptchaSiteKey) {
      const recaptcha = await load(recaptchaSiteKey)
      postData.token = await recaptcha.execute("contact")
    } else {
      console.error("recaptcha site key is not set.")
    }

    axios
      .post(baseURL + "/contact/", postData)
      .then(() => {
        setStatus("success")
      })
      .catch(() => {
        setStatus("failure")
      })
  }
  return (
    <div className={classes.ContactForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>{t("Your Name")}</label>
        <input
          className={errors.name ? classes.InputError : classes.Input}
          name="name"
          ref={register({ required: t("This field is required.") as string })}
        />
        <div className={classes.ErrorContainer}>
          {errors.name && (
            <p className={classes.ErrorText}>{errors.name.message}</p>
          )}
        </div>
        <label>{t("Your E-mail Address")}</label>
        <input
          className={errors.email ? classes.InputError : classes.Input}
          name="email"
          ref={register({
            required: t("This field is required.") as string,
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: t("Email address seems invalid."),
            },
          })}
        />
        <div className={classes.ErrorContainer}>
          {errors.email && (
            <p className={classes.ErrorText}>{errors.email.message}</p>
          )}
        </div>
        <label>{t("E-mail Address (Confirmation)")}</label>
        <input
          className={errors.emailConfirm ? classes.InputError : classes.Input}
          name="emailConfirm"
          ref={register({
            validate: value =>
              value === watch("email") ||
              (t("Email address does not match.") as string),
          })}
        />
        <div className={classes.ErrorContainer}>
          {errors.emailConfirm && (
            <p className={classes.ErrorText}>{errors.emailConfirm.message}</p>
          )}
        </div>
        <label>{t("Subject")}</label>
        <input
          className={errors.subject ? classes.InputError : classes.Input}
          name="subject"
          ref={register({ required: t("This field is required.") as string })}
        />
        <div className={classes.ErrorContainer}>
          {errors.subject && (
            <p className={classes.ErrorText}>{errors.subject.message}</p>
          )}
        </div>
        <label>{t("Message")}</label>
        <textarea
          className={errors.body ? classes.TextAreaError : classes.TextArea}
          name="body"
          ref={register({ required: t("This field is required.") as string })}
        />
        <div className={classes.ErrorContainer}>
          {errors.body && (
            <p className={classes.ErrorText}>{errors.body.message}</p>
          )}
        </div>
        <div>
          {status === "loading" && (
            <p className={classes.LoadingMessage}>
              {t("Now sending your message. Please wait a moment.")}
            </p>
          )}
          {status === "success" && (
            <p className={classes.SuccessMessage}>
              {t(
                "Your message has been sent successfully. Thank you for your contact."
              )}
            </p>
          )}
          {status === "failure" && (
            <p className={classes.FailureMessage}>
              {t("Failed to send message. Please try again later.")}
            </p>
          )}
        </div>
        <div className={classes.ButtonWrapper}>
          <Button
            name={t("Submit")}
            submit={status === "init" || status === "failure"}
            disabled={status === "loading" || status === "success"}
          />
        </div>
      </form>
    </div>
  )
}

export default ContactForm
