import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Img from "gatsby-image"

import dealerInformation from "../../dealersRoom"
import classes from "./DealerInformation.module.scss"

const DealerInformation: React.FC = () => {
  const { i18n } = useTranslation()
  let localedDealerInformation
  let linkDisplay: string
  switch (i18n.language) {
    case "ja":
      localedDealerInformation = dealerInformation.ja
      linkDisplay = "ウェブサイト"
      break
    default:
      localedDealerInformation = dealerInformation.en
      linkDisplay = "Website"
  }

  const data = useStaticQuery<GatsbyTypes.DealerImageQuery>(graphql`
    query DealerImage {
      images: allFile(
        filter: { relativePath: { regex: "$/dealerBoothImages/" } }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 350, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={classes.container}>
      {localedDealerInformation.map((item, index: number) => {
        return (
          <div className={classes.dealerItem} key={index}>
            <p>{item.booth}</p>
            <Img
              className={classes.boothImage}
              fluid={
                data.images.edges.find(
                  el => el.node.relativePath === "dealerBoothImages/" + item.img
                )?.node.childImageSharp?.fluid
              }
            />
            <h3>{item.boothName}</h3>
            <h4>{item.name}</h4>
            <p className={classes.description}>
              {item.description
                .split("<br>")
                .map((text: string, index: number) => {
                  const regex = /<span>(?<text>.*)<\/span>/
                  const matchText = text.match(regex)
                  const description =
                    matchText === null ? (
                      text
                    ) : (
                      <span>{matchText.groups?.text}</span>
                    )

                  return (
                    <React.Fragment key={index}>
                      {description}
                      <br />
                    </React.Fragment>
                  )
                })}
            </p>
            {item.url.length === 0 ? null : (
              <a href={item.url} rel="noopener noreferrer" target="_blank">
                {linkDisplay}
              </a>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default DealerInformation
