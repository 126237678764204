import React from "react"

import StoryContestWork from "../atoms/content/StoryContestWork"
import {
  shortShortWorks2021,
  shortWorks2021,
} from "../../storyContest/2021/storyContestWorks"
import {
  shortShortWorks2022,
  shortWorks2022,
} from "../../storyContest/2022/storyContestWorks"
import {
  shortShortWorks2023,
  shortWorks2023,
} from "../../storyContest/2023/storyContestWorks"
import classes from "./StoryContestWorks.module.scss"

type Props = {
  kind: "shortShort" | "short"
  year: year
}

type year = "2021" | "2022" | "2023"

const StoryContestWorks: React.FC<Props> = ({ kind, year }) => {
  let shortShortWorks = shortShortWorks2023
  let shortWorks = shortWorks2023

  switch (year) {
    case "2021":
      shortShortWorks = shortShortWorks2021
      shortWorks = shortWorks2021
      break
    case "2022":
      shortShortWorks = shortShortWorks2022
      shortWorks = shortWorks2022
      break
    case "2023":
      shortShortWorks = shortShortWorks2023
      shortWorks = shortWorks2023
      break
  }

  return (
    <>
      <div className={classes.StoryContestWorks}>
        <p>
          <em>
            ※作品は、作者の同意の上、応募された状態のままで掲載しております。
          </em>
        </p>
        {kind === "shortShort"
          ? shortShortWorks.map(el => (
              <StoryContestWork data={el} year={year} key={el.title} />
            ))
          : shortWorks.map(el => (
              <StoryContestWork data={el} year={year} key={el.title} />
            ))}
      </div>
    </>
  )
}

export default StoryContestWorks
