import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import classes from "./GuestOfHonorWorks.module.scss"

type Work = {
  src: string
  title: string
}

const GuestOfHonorWorks = () => {
  const { i18n } = useTranslation()
  let works: Work[] = []

  switch (i18n.language) {
    case "ja":
      works = [
        {
          src: "/img/guestOfHonor/JMF24_GoH_image_2.jpg",
          title: "▲『四賢者』",
        },
        {
          src: "/img/guestOfHonor/JMF24_GoH_image_3.jpg",
          title: "▲『ポーションラボ』",
        },
      ]
      break
    case "en":
      works = [
        {
          src: "/img/guestOfHonor/JMF24_GoH_image_2.jpg",
          title: "▲“the Four Wise Men”",
        },
        {
          src: "/img/guestOfHonor/JMF24_GoH_image_3.jpg",
          title: "▲“Potion Lab”",
        },
      ]
  }

  return (
    <div className={classes.container}>
      {works.map((work, index) => {
        return (
          <div className={classes.work} key={index}>
            <div>
              <img src={work.src} alt={work.title} />
              <p>{work.title}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default GuestOfHonorWorks
