import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Img from "gatsby-image"
import rafflePrize from "../../rafflePrize"
import classes from "./RafflePrizeList.module.scss"

const RafflePrizeList: React.FC = () => {
  const { i18n } = useTranslation()
  let localedRafflePrizeList
  switch (i18n.language) {
    case "ja":
      localedRafflePrizeList = rafflePrize.ja
      break
    default:
      localedRafflePrizeList = rafflePrize.en
  }

  const data = useStaticQuery<GatsbyTypes.RafflePrizeImageQuery>(graphql`
    query RafflePrizeImage {
      images: allFile(filter: { relativePath: { regex: "$/rafflePrizes/" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 450, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const formatBreak = (text: string) => {
    return text.split("<br>").map((text: string, index: number) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ))
  }

  return (
    <div className={classes.container}>
      {localedRafflePrizeList.map((item, index: number) => {
        let provider
        switch (i18n.language) {
          case "ja":
            provider = (
              <>
                <a
                  href={item.providerLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.provider}
                </a>{" "}
                様 ご提供
              </>
            )
            break
          default:
            provider = (
              <>
                Donated by{" "}
                <a
                  href={item.providerLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.provider}
                </a>
              </>
            )
            localedRafflePrizeList = rafflePrize.en
        }

        return (
          <div className={classes.rafflePrizeItem} key={index}>
            <div className={classes.itemImage}>
              {item.img.map(src => {
                return (
                  <Img
                    key={src}
                    fluid={
                      data.images.edges.find(
                        el => el.node.relativePath === "rafflePrizes/" + src
                      )?.node.childImageSharp?.fluid
                    }
                    imgStyle={{ objectFit: "contain" }}
                  />
                )
              })}
            </div>
            {item.description.map((description, index: number) => {
              if (description.prefix === "note") {
                return (
                  <div className={classes.description} key={index}>
                    <p>{description.text}</p>
                  </div>
                )
              }
            })}
            <h3 className={classes.title}>{item.title}</h3>
            <h4 className={classes.provider}>{provider}</h4>
            {item.description.map((description, index: number) => {
              let descriptionDOM =
                description.prefix !== "note" ? (
                  <>
                    <span className={classes.prefix}>
                      {description.prefix}：
                    </span>
                    {formatBreak(description.text)}
                  </>
                ) : null

              if (description.href) {
                descriptionDOM = <a href={description.href}>{descriptionDOM}</a>
              }

              return (
                <div className={classes.description} key={index}>
                  {descriptionDOM}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default RafflePrizeList
