import React from "react"

import GrandPrix from "../../../images/contents/story-contest-decoration.svg"
import classes from "./StoryContestDecoration.module.scss"

const StoryContestDecoration: React.FC = () => {
  return <GrandPrix className={classes.StoryContestDecoration} />
}

export default StoryContestDecoration
