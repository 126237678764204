import React from "react"
import clsx from "clsx"

import classes from "./Button.module.scss"

export type ButtonProps = {
  name: string
  to?: string
  disabled?: boolean
  submit?: boolean
  anchor?: boolean
  noUppercase?: boolean
}

const Button: React.FC<ButtonProps> = props => {
  const noUppercase = props.noUppercase ? classes.ButtonNoUppercase : null
  return props.disabled ? (
    <button className={clsx(classes.ButtonDisabled, noUppercase)} disabled>
      {props.name}
    </button>
  ) : props.submit ? (
    <button className={clsx(classes.ButtonSubmit, noUppercase)} type="submit">
      {props.name}
    </button>
  ) : (
    <a
      href={props.to}
      className={clsx(classes.Button, noUppercase)}
      target={props.anchor ? "_self" : "_blank"}
      rel={props.anchor ? "" : "noopener noreferrer"}
    >
      {props.name}
    </a>
  )
}

export default Button
