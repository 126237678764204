import React from "react"
import Img from "gatsby-image"

import classes from "./ContentTitleImage.module.scss"

type Props = {
  titleImage: {
    node: {
      relativePath: string
      name: string
      childImageSharp: {
        fluid: {
          sizes: string
          base64: string
          aspectRatio: number
          src: string
          srcSet: string
        }
      }
      childInlineSvg: {
        content: string
      }
    }
  }
}

const ContentTitleImage: React.FC<Props> = props => {
  return (
    <div className={classes.ContentTitleImage}>
      {props.titleImage.node.relativePath.slice(-4).toLowerCase() === ".svg" ? (
        <div
          className={classes.SvgWrapper}
          dangerouslySetInnerHTML={{
            __html: props.titleImage.node.childInlineSvg.content,
          }}
        />
      ) : (
        <Img
          fluid={props.titleImage.node.childImageSharp.fluid}
          style={{ height: "200px" }}
          imgStyle={{ objectFit: "contain" }}
        />
      )}
    </div>
  )
}

export default ContentTitleImage
