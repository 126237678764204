import React from "react"
import { I18nextContext } from "gatsby-plugin-react-i18next"

import classes from "./ContentTab.module.scss"

export type ContentTabProps = {
  name: string
  tabId: string
  defaultCheckedPath: string[]
}

const ContentTab: React.FC<ContentTabProps> = props => {
  const { originalPath } = React.useContext(I18nextContext)

  return (
    <>
      <input
        type="radio"
        id={props.name}
        name={props.tabId}
        defaultChecked={props.defaultCheckedPath.includes(originalPath)}
        className={classes.Switch}
      />
      <label className={classes.Label} htmlFor={props.name}>
        {props.name}
      </label>
      <div className={classes.Content}>{props.children}</div>
    </>
  )
}

export default ContentTab
