import React, { useEffect, useState } from "react"
import Button from "../atoms/common/Button"

import classes from "./HotelInformation.module.scss"

const API_BASE = process.env.GATSBY_API_BASE_URL

export type Props = {
  language: "en" | "ja"
}

type hotelData = {
  display_flag: boolean | "false" | "true"
  url: string
  url_en: string
  id: string
  password: string
}

const HotelInformation: React.FC<Props> = ({ language }) => {
  const [hotelData, setHotelData] = useState<hotelData>()

  let template = <></>
  if (
    (typeof hotelData?.display_flag === "string"
      ? hotelData?.display_flag !== "false"
      : hotelData?.display_flag) &&
    hotelData?.id &&
    hotelData?.password &&
    hotelData?.url &&
    hotelData?.url_en
  ) {
    switch (language) {
      case "en":
        template = (
          <>
            <h3>Website for Room Reservations for Loisir Hotel Toyohashi</h3>
            <ul>
              <li>Account: {hotelData.id}</li>
              <li>Access key: {hotelData.password}</li>
            </ul>
            <div className={classes.Button}>
              <Button
                name="Click Here to the Website for Room Reservation for Loisir Hotel Toyohashi"
                to={hotelData.url_en}
              />
            </div>
          </>
        )
        break
      case "ja":
        template = (
          <>
            <h3>ロワジールホテル豊橋 宿泊予約サイト</h3>
            <ul>
              <li>アカウント: {hotelData.id}</li>
              <li>アクセスキー : {hotelData.password}</li>
            </ul>
            <div className={classes.Button}>
              <Button
                name="ロワジールホテル豊橋 宿泊予約サイトはこちら"
                to={hotelData.url}
              />
            </div>
          </>
        )
        break
    }
  }

  useEffect(() => {
    fetch(`${API_BASE}/hotel_reservation`)
      .then(response => response.json())
      .then(data => setHotelData(data))
  }, [])

  return template
}

export default HotelInformation
