import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import Item, { Props as ItemProps } from "../molecules/goodsList/Item"
import classes from "./GoodsList.module.scss"

const items: (Omit<ItemProps, "image"> & { image: string })[] = [
  {
    title: {
      ja: "JMoF 2024 ボストンバッグ（2種）",
      en: "JMoF 2024 Boston Bag (2 colors)",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 7000,
    image: "JMF24_RG_goods_00.png",
  },
  {
    title: {
      ja: "JMoF 2024 イラストマウスパッド",
      en: "JMoF 2024 Mouse Pad",
    },
    description: {
      ja: (
        <>
          <p>RGB LEDで複数パターンに光ります</p>
          <p>サイズ：W 600 × H 300 mm、厚さ4mm</p>
        </>
      ),
      en: (
        <>
          <p>Glowing in multiple patterns with RGB LEDs!</p>
          <p>Size: W 600 x H 300 mm, T 4 mm</p>
        </>
      ),
    },
    price: 4500,
    image: "JMF24_RG_ComingSoon.png",
  },
  {
    title: {
      ja: "JMoF 2024 公式Tシャツ",
      en: "JMoF 2024 Official T-shirt",
    },
    description: {
      ja: "サイズ：S/M/L/XL/XXL/XXXL",
      en: "Sizes: S/M/L/XL/XXL/XXXL",
    },
    price: 2500,
    image: "JMF24_RG_goods_02.png",
  },
  {
    title: {
      ja: "JMoF 2024 ネックストラップ",
      en: "JMoF 2024 Lanyard",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 800,
    image: "JMF24_RG_goods_03.png",
  },
  {
    title: {
      ja: "JMoF 2024 トートバッグ",
      en: "JMoF 2024 Tote Bag",
    },
    description: {
      ja: "サイズ（概形）：W 360 × H 370 × D 110 mm",
      en: "Size: W 360 x L 370 x D 110 mm",
    },
    price: 2000,
    image: "JMF24_RG_goods_04.png",
  },
  {
    title: {
      ja: "JMoF 2024 クラシック棒",
      en: "JMoF 2024 Classic Hotel Keychain",
    },
    description: {
      ja: "サイズ：W 180 × H 20 × D 15 mm",
      en: "Size: W 180 x H 20 x D 15 mm",
    },
    price: 2500,
    image: "JMF24_RG_goods_05.png",
  },
  {
    title: {
      ja: "JMoF 2024 フェイスタオル",
      en: "JMoF 2024 Face Towel",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 1000,
    image: "JMF24_RG_goods_06.png",
  },
  {
    title: {
      ja: "VR JMoF 2024 ストラップ",
      en: "VR JMoF 2024 Strap",
    },
    description: {
      ja: "ゲーム／VR機器用の手首ストラップにもぴったり",
      en: "Also perfect as wrist straps for game/VR controllers!",
    },
    price: 1000,
    image: "JMF24_RG_ComingSoon.png",
  },
  {
    title: {
      ja: "JMoF 2024 ピンバッジ",
      en: "JMoF 2024 Pin Badge",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 700,
    image: "JMF24_RG_goods_08.png",
  },
  {
    title: {
      ja: "JMoF 2024 マスキングテープ（2種）",
      en: "JMoF 2024 Washi Tape (2 patterns)",
    },
    description: {
      ja: "サイズ：幅15mm",
      en: "Size: W 15 mm",
    },
    price: 600,
    image: "JMF24_RG_ComingSoon.png",
  },
  {
    title: {
      ja: "JMoF 2024 プラスチックキーホルダー",
      en: "JMoF 2024 Acrylic Keychain",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 500,
    image: "JMF24_RG_goods_10.png",
  },
  {
    title: {
      ja: "JMoF 2024 ステッカー（5種）",
      en: "JMoF 2024 Sticker",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 300,
    image: "JMF24_RG_goods_11.png",
  },
  {
    title: {
      ja: "JMoF 2024 クリアファイル（2種）",
      en: "JMoF 2024 File Folder",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 300,
    image: "JMF24_RG_goods_12.png",
  },
  {
    title: {
      ja: "JMoF 2024 ポスター",
      en: "JMoF 2024 Poster",
    },
    description: {
      ja: "サイズ：B2",
      en: "Size: JIS B2",
    },
    price: 300,
    image: "JMF24_RG_ComingSoon.png",
  },
]

// 過去グッズ
const oldItems: (Omit<ItemProps, "image"> & { image: string })[] = [
  {
    title: {
      ja: "JMoF 2023 公式Tシャツ",
      en: "JMoF 2023 Official T-shirt",
    },
    description: {
      ja: "サイズ：S/M/L/XL/XXL/XXXL",
      en: "Sizes: S/M/L/XL/XXL/XXXL",
    },
    price: 2500,
    image: "JMF24_RG_goods_14.png",
  },
  {
    title: {
      ja: "JMoF 2023 バンダナ",
      en: "JMoF 2023 Bandana",
    },
    description: {
      ja: "サイズ：W 52 × H 52 cm",
      en: "Size: W 52 x H 52 cm",
    },
    price: 1300,
    image: "JMF24_RG_goods_15.png",
  },
  {
    title: {
      ja: "JMoF 2023 フェイスタオル",
      en: "JMoF 2023 Face Towel",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 800,
    image: "JMF24_RG_goods_16.png",
  },
  {
    title: {
      ja: "JMoF 2023 クリアファイル",
      en: "JMoF 2023 File Folder",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 300,
    image: "JMF24_RG_goods_17.png",
  },
  {
    title: {
      ja: "JMoFオリジナルボールペン（SARASA）",
      en: "JMoF Original Ballpoint Pen (SARASA)",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 200,
    image: "JMF24_RG_goods_18.png",
  },
  {
    title: {
      ja: "JMoF 2022 フェイスタオル",
      en: "JMoF 2022 Face Towel",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 800,
    image: "JMF24_RG_goods_19.png",
  },
  {
    title: {
      ja: "JMoF 2022 扇子",
      en: "JMoF 2022  Folding Fan",
    },
    description: {
      ja: (
        <>
          <p>
            サイズ：7寸30間
            <br />
            仕様：フルカラー布貼り、箱・収納袋付属、携帯に嬉しい房付き
          </p>
        </>
      ),
      en: (
        <>
          <p>
            Size: About 21cm, 30 ridges
            <br />
            Features: Full color cloth wrap, box and storage bag included, a
            tassel perfect for carriage included
          </p>
        </>
      ),
    },
    price: 2000,
    image: "JMF24_RG_goods_20.png",
  },
  {
    title: {
      ja: "JMoF 2022 公式Tシャツ",
      en: "JMoF 2022 Official T-Shirt",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 1000,
    image: "JMF24_RG_goods_21.png",
  },
  {
    title: {
      ja: "JMoF 2022 ピンバッジ",
      en: "JMoF 2022 Pin Badge",
    },
    description: {
      ja: "",
      en: "",
    },
    price: 600,
    image: "JMF24_RG_goods_22.png",
  },
  {
    title: {
      ja: "JMoF 2022 ポスター",
      en: "JMoF 2022 Poster",
    },
    description: {
      ja: "サイズ：B2",
      en: "Size: JIS B2 (W 515 x H 728 mm)",
    },
    price: 300,
    image: "JMF24_RG_goods_23.png",
  },
  {
    title: {
      ja: "JMoF 2023 ポスター",
      en: "JMoF 2023 Poster",
    },
    description: {
      ja: "サイズ：B2",
      en: "Size: JIS B2 (W 515 x H 728 mm)",
    },
    price: 300,
    image: "JMF24_RG_goods_24.png",
  },
]

const GoodsList: FC = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const images = useStaticQuery<GatsbyTypes.GoodsImagesQuery>(graphql`
    query GoodsImages {
      images: allFile(filter: { relativePath: { regex: "$/store/" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 450, maxHeight: 450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      oldImages: allFile(filter: { relativePath: { regex: "$/store/" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 450, maxHeight: 450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={classes.Wrapper}>
        {items.map(item => (
          <Item
            key={item.title.ja}
            {...item}
            image={
              images.images.edges.find(edge =>
                edge.node.relativePath.endsWith(item.image)
              )?.node.childImageSharp?.fluid
            }
          />
        ))}
      </div>
      <h3>{language === "ja" ? "過去のグッズ" : "Previous Years’"}</h3>
      <div className={classes.Wrapper}>
        {oldItems.map(item => (
          <Item
            key={item.title.ja}
            {...item}
            image={
              images.oldImages.edges.find(edge =>
                edge.node.relativePath.endsWith(item.image)
              )?.node.childImageSharp?.fluid
            }
          />
        ))}
      </div>
    </>
  )
}
export default GoodsList
