export const shortShortWorks2022 = [
  { title: "アルタイル", author: "すあ" },
  { title: "ずっと一緒だよ", author: "ストロム" },
  { title: "なななななな", author: "とーや" },
  { title: "７本指の行方", author: "Yoshitugu Tuduki" },
  { title: "虹色の羽", author: "ラグス" },
  { title: "君みたいになりたい", author: "リング" },
  { title: "カミもケモノのうち", author: "れぐれす" },
]

export const shortWorks2022 = [
  { title: "ノールとナナの街", author: "岸間夜行" },
  { title: "なんてこともない", author: "コリンス" },
  { title: "『７』", author: "ストロム" },
  { title: "鼻息荒いぞ、豚野郎！", author: "だいご" },
  { title: "彼の翼がまぶしくて", author: "辰巳ルペ" },
  { title: "たった一つのオレの罪", author: "仁王立ちクララ" },
  { title: "大人になる夏", author: "響音カゲ" },
  { title: "七匹の容疑者", author: "モリモリ" },
  { title: "七つ尾のふたり", author: "やまねこ　よだか" },
  { title: "異端の言葉", author: "ラグス" },
  { title: "欲を飼いならせば", author: "リング" },
]
