import React from "react"
import classes from "./CharityPicture.module.scss"

const CharityPicture = () => {
  return (
    <div className={classes.container}>
      <img src="/img/charity/2021チンパンジー遊具.jpg" alt="チンパンジー遊具" />
      <img src="/img/charity/2022カンガルーデッキ.jpg" alt="カンガルーデッキ" />
      <img src="/img/charity/2023サーバル舎.jpg" alt="サーバル舎" />
    </div>
  )
}

export default CharityPicture
