export const shortShortWorks2021 = [
  { title: "僕とおてて", author: "モリモリ", award: "grandPrix" as const },
  { title: "お隣さん", author: "とぅや", award: "grandPrix" as const },
  {
    title: "いつかの恩返し",
    author: "辰巳ルペ",
    award: "grandPrixCandidate" as const,
  },
  { title: "コーラス", author: "＠Ｙ", award: "grandPrixCandidate" as const },
  { title: "またあした", author: "楓麟", award: "winning" as const },
  {
    title: "キグルンデ大サーカス",
    author: "だいご",
    award: "winning" as const,
  },
  { title: "変わりゆく夕日", author: "夜狐紺", award: "winning" as const },
  { title: "美味しいラーメン", author: "ラグス", award: "winning" as const },
  { title: "赤き眼、暗黒に輝きて", author: "Yoshitugu Tuduki" },
  { title: "告白", author: "虚偽" },
  { title: "夜の蝶の裏の顔", author: "Ring_chatot" },
  { title: "普通の人ではいられない", author: "烙雷" },
  { title: "すれ違いコント", author: "伊藤テル" },
  { title: "彼は、", author: "霧島化猫（きりしまばけ）" },
  { title: "よもやまコート戦争", author: "けもにゃん" },
  { title: "いい子だね！", author: "化け犬" },
  { title: "決意　～鬱病と自閉症スペクトラム～", author: "ストロム" },
]

export const shortWorks2021 = [
  { title: "長い冬", author: "楽来一知", award: "grandPrix" as const },
  {
    title: "今日、気付いた",
    author: "大竹和竜",
    award: "grandPrixCandidate" as const,
  },
  { title: "忠犬の掟", author: "ラグス", award: "grandPrixCandidate" as const },
  { title: "イマジナリー", author: "岸間夜行", award: "winning" as const },
  { title: "影の在処", author: "キプラ", award: "winning" as const },
  {
    title: "魂の自由、その器のカタチ",
    author: "コンノスケ",
    award: "winning" as const,
  },
  { title: "間の悪い刑事", author: "大潮十六夜" },
  { title: "獣の童話の別の解", author: "貴金属" },
  { title: "のじゃたぬ令嬢作家志望！", author: "夜狐紺" },
  { title: "出来ないことばかりの魔法の国", author: "Ring_chatot" },
  { title: "ラビットフット", author: "コリンス" },
]
