import React from "react"

import ContentTitleImage from "../atoms/content/ContentTitleImage"
import classes from "./ContentTitle.module.scss"

type Props = {
  title: string
  titleImage?: {
    node: {
      relativePath: string
      name: string
      childImageSharp: {
        fluid: {
          sizes: string
          base64: string
          aspectRatio: number
          src: string
          srcSet: string
        }
      }
      childInlineSvg: {
        content: string
      }
    }
  }
  titleColor?: string
}

const ContentTitle: React.FC<Props> = props => {
  return (
    <div
      className={classes.ContentTitle}
      style={{ backgroundColor: props.titleColor }}
    >
      {props.titleImage ? (
        <ContentTitleImage titleImage={props.titleImage} />
      ) : (
        <h1>{props.title}</h1>
      )}
    </div>
  )
}

export default ContentTitle
