import React, { FC, ReactElement } from "react"
import Img, { FluidObject } from "gatsby-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Description from "../../atoms/goodsList/Description"
import Price from "../../atoms/goodsList/Price"
import classes from "./Item.module.scss"

export type Props = {
  title: { ja: string; en: string }
  description: { ja: string | ReactElement; en: string | ReactElement }
  price: number
  image?: FluidObject
}
const Item: FC<Props> = ({ title, description, price, image }) => {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <div className={classes.Item}>
      {image && <Img fluid={image} />}
      <h3>{language === "ja" ? title.ja : title.en}</h3>
      <Description>
        {language === "ja" ? description.ja : description.en}
      </Description>
      <Price>{price}</Price>
    </div>
  )
}
export default Item
