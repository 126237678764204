import React from "react"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { PageProps } from "gatsby"

import SEO from "../utils/seo"
import ContentTitle from "../components/organisms/ContentTitle"
import ContentTabs from "../components/atoms/content/ContentTabs"
import ContentTab from "../components/atoms/content/ContentTab"
import Button, { ButtonProps } from "../components/atoms/common/Button"
import AnchorHead from "../components/atoms/common/AnchorHead"
import ContactForm from "../components/organisms/ContactForm"
import StoryContestWorks from "../components/organisms/StoryContestWorks"
import StoryContestWorkTitle from "../components/molecules/contents/StoryContestWorkTitle"
import HotelInformation, {
  Props as HotelInformationProps,
} from "../components/organisms/HotelInformation"
import Right from "../components/atoms/common/Right"
import DealerInformation from "../components/organisms/DealerInformation"
import RafflePrizeList from "../components/organisms/RafflePrizeList"
import GoodsList from "../components/organisms/GoodsList"

import GuestOfHonorWorks from "../components/organisms/GuestOfHonorWorks"
import CharityPicture from "../components/atoms/charity/CharityPicture"

import classes from "./ContentTemplate.module.scss"

type otherLanguageType = {
  code: string
  text: string
}

type frontmatterType = {
  title: string
  slug: string
  titleImage?: string
  titleColor?: string
  noTitle?: boolean
  otherLanguages?: otherLanguageType[]
}

type pageContextType = {
  ja: {
    body: string
    frontmatter: frontmatterType
  }
  en: {
    body: string
    frontmatter: frontmatterType
  }
  zh_Hans: {
    body: string
    frontmatter: frontmatterType
  }
  zh_Hant: {
    body: string
    frontmatter: frontmatterType
  }
  titleImage: {
    node: {
      relativePath: string
      name: string
      childImageSharp: {
        fluid: {
          sizes: string
          base64: string
          aspectRatio: number
          src: string
          srcSet: string
        }
      }
      childInlineSvg: {
        content: string
      }
    }
  }
}

type Props = PageProps & {
  pageContext: pageContextType
}

const ContentPageTemplate: React.FC<Props> = ({ pageContext }) => {
  const { i18n } = useTranslation()
  let frontmatter: frontmatterType, body
  switch (i18n.language) {
    case "ja":
      // eslint-disable-next-line
      ;({ frontmatter, body } = pageContext.ja)
      break
    case "zh_Hans":
      // eslint-disable-next-line
      ;({ frontmatter, body } = pageContext.zh_Hans)
      break
    case "zh_Hant":
      // eslint-disable-next-line
      ;({ frontmatter, body } = pageContext.zh_Hant)
      break
    default:
      // eslint-disable-next-line
      ;({ frontmatter, body } = pageContext.en)
  }

  const { originalPath } = useI18next()

  return (
    <>
      <SEO title={frontmatter.title} />

      <ContentTitle
        title={frontmatter.title}
        titleImage={pageContext.titleImage}
        titleColor={frontmatter.titleColor}
      />

      {frontmatter.otherLanguages && (
        <div className={classes.Content}>
          <div className={classes.ChangeLanguageLinks}>
            {frontmatter.otherLanguages.map(function (
              otherLanguage: otherLanguageType
            ) {
              return (
                <Link
                  key={otherLanguage.code}
                  to={originalPath}
                  language={otherLanguage.code}
                  className={classes.Item}
                >
                  {otherLanguage.text}
                </Link>
              )
            })}
          </div>
        </div>
      )}

      {!frontmatter.noTitle && (
        <div className={classes.TitleContainer}>
          <h1 className={classes.Title}>{frontmatter.title}</h1>
        </div>
      )}

      <div className={classes.Content}>
        <MDXProvider
          components={{
            ContentTabs: ContentTabs,
            ContentTab: ContentTab,
            Button: function ButtonWithClass(props: ButtonProps) {
              return (
                <div className={classes.Button}>
                  <Button {...props} />
                </div>
              )
            },
            AnchorHead: AnchorHead,
            ContactForm: ContactForm,
            StoryContestWorks: StoryContestWorks,
            StoryContestWorkTitle: StoryContestWorkTitle,
            HotelInformation: function HotelInformationWithProps(
              props: HotelInformationProps
            ) {
              return <HotelInformation {...props} />
            },
            StoryContestNovel: function StoryContestNovel({
              children,
            }: {
              children: React.ReactNode
            }) {
              return <div className={classes.StoryContestNovel}>{children}</div>
            },
            ArtCompetitionResults: function ArtCompetitionResults({
              children,
            }: {
              children: React.ReactNode
            }) {
              return (
                <div className={classes.ArtCompetitionResults}>{children}</div>
              )
            },
            VirusCountermeasureImportant: function VirusCountermeasureImportant({
              children,
            }: {
              children: React.ReactNode
            }) {
              return (
                <span className={classes.VirusCountermeasureImportant}>
                  {children}
                </span>
              )
            },
            Right,
            DealerInformation,
            RafflePrizeList,
            GoodsList,
            GuestOfHonorWorks,
            CharityPicture,
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </>
  )
}

export default ContentPageTemplate
