import React, { FC } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

type Props = { children: number }
const Price: FC<Props> = ({ children }) => {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <h4>
      {language === "ja" ? (
        <>価格：{children.toLocaleString()}円</>
      ) : (
        <>Price: {children.toLocaleString()} JPY</>
      )}
    </h4>
  )
}
export default Price
